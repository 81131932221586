<template>
  <v-col class="col-lg-3 col-md-6 col-sm-12 pa-2 flex-grow-1">
    <div class="our-plan pa-4 pl-5" style="height: 100%">
      <v-row class="d-flex flex-column flex-grow-1">
        <v-col class="py-0">
          <h6 v-if="!isCustomPlan" class="our-plan__title"> {{ details.name }}</h6>
          <h6 v-else class="our-plan__title">Custom</h6>
        </v-col>


        <v-col class="py-0">
          <div v-if="!isCustomPlan">
            <div class="our-plan__price-currency my-5 my-sm-2">
              <p>AED <span class="our-plan__price text-sm-body-1">{{ `${details.price}` }}</span> /mo</p>
            </div>
            <v-container class="px-3" fluid>
              <v-btn block color="primary" class="text-transform-none" depressed @click.native="navigate(details)">
                Start your FREE trial
              </v-btn>
            </v-container>
          </div>

          <div v-else>
            <h6 class="our-plan__custom-plan-title">
              Create a plan for your B2B and B2C needs.
            </h6>
            <v-container fluid class="py-0 px-3">
              <v-form ref="customForm">
                <v-text-field v-model="phone" label="Phone" class="mb-2" outlined dense hide-details :rules="rules.phoneRules"></v-text-field>
                <v-text-field v-model="email" label="Email" class="mb-2" outlined dense hide-details :rules="rules.emailRules"></v-text-field>
                <v-btn block color="primary" class="text-transform-none"  depressed @click="submitCustomForm" :loading="loading">Customize
                  your plan
                </v-btn>
              </v-form>
            </v-container>
          </div>
        </v-col>

        <v-col class="py-0">
          <slot name="plan-features"></slot>
        </v-col>


        <v-col class="py-0">
          <p v-if="!isCustomPlan" class="our-plan__plan-footer">
            Cost can vary based on the fulfillment center location and shipping destination, as well as other value-added
            services.
          </p>
        </v-col>
      </v-row>
    </div>
  </v-col>

</template>

<script>
import mixins from "vue-typed-mixins"
import MyMixin from "@/plugins/myMixin";
import {PlanRequest} from "@/integration/plan-form";
import {types, sub_types} from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  props: {
    details: Object,
    isCustomPlan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      phone: "",
      email: "",
      rules: {
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phoneRules: [
          (v) => !!v || "Phone is required",
          (v) =>
              /^[\d ]*$/.test(v) || "Phone must be valid (digits only)",
          (v) =>
              (v && v.replace(/\s/g, "").length >= 9) ||
              "Phone must be at least 9 characters",
        ],
      },
    };
  },
  methods: {
    navigate(plan) {
      this.select(plan.id);
      this.$router.push({name: "Plan-details", params: {slug: plan.slug}})
    },
    select(id) {
      this.logGAEvent('plan', `Package ${id} selected`);
    },
    submitCustomForm() {
      if (!this.$refs.customForm.validate()) {
        return;
      }
      this.loading = !this.loading;
      PlanRequest
          .send({
            type: types.Subscription,
            sub_type: sub_types.Custom,
            name: '',
            email: this.email,
            phone: this.phone,
          })
          .then(({data: record}) => {
            // Clear
            this.email = '';
            this.phone = '';
            this.$refs.customForm.reset();

            this.logGAEvent(
                "plan",
                `Submit package 4 selected`,
                this.$route.name
            );
            this.$router.push({name: 'plan-thank-you', params: {package: '4'}})
          })
          .catch((err) => {
            this.logGAException(err);
          })
          .finally(() => {
            this.loading = !this.loading;
          });
    },
  }
});
</script>

<style>
.text-transform-none {
  text-transform: none;
  font-size: 17px !important;
  letter-spacing: normal;
  border-radius: 3px!important;
}

.our-plan.col-md-3 {
  width: 24%;
}

@media screen and (max-width: 600px) {
  .v-application .my-sm-2 {
    margin: 10px 0!important;
  }

  .our-plan__custom-plan-title {
    margin-top: 5px;
    margin-bottom: 8px;
  }
}
</style>
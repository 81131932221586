<template>
  <v-container fluid>
    <v-row class="home-page__plans d-md-flex flex-row justify-space-between">
      <Plan :details="plan" v-for="(plan,index) in plans" :key="index" >
        <div slot="plan-features">
          <ul class="px-3 home-page__plan-features">
            <li v-for="(item, index) in plan.items" :key="index">
              <template>
                <img class="home-page__plan-icon" :src="require(`@/assets/imgs/plan/${item.icon}`)" alt="">
                <div class="home-page__feature">
                  <span v-text="item.text"></span>
                  <span v-text="item.value"></span>
                </div>
              </template>
            </li>
          </ul>
          <ul class="px-3">
            <li v-for="(item, index) in extra" :key="index">
              <template>
                <img class="home-page__plan-icon" :src="require(`@/assets/imgs/plan/${item.icon}`)" alt="">
                <div class="home-page__feature">
                  <span class="font-weight-regular" v-text="item.text"></span>
                  <span v-text="item.value"></span>
                </div>
              </template>
            </li>
          </ul>
        </div>
      </Plan>

      <Plan :isCustomPlan="true">
        <div slot="plan-features">
          <ul class="home-page__custom-plan-ul">
            <li class="home-page__custom-plan" v-for="(feature,index) in customPlanFeatures" :key="index">
              <span> <img src="@/assets/imgs/plan/Vector.png" alt=""> </span>
              <span> {{feature}} </span>
            </li>
          </ul>
        </div>
      </Plan>
    </v-row>
  </v-container>
</template>

<script>
import Plan from './Plan.vue'
import plansMixins from '@/mixins/plans.js';
export default {
    mixins: [plansMixins],
    components:{Plan},
    data(){
        return {
            
        }
    }
}
</script>

<style>

</style>
<template>
  <div id="plans">
    <WsLogoHeader />
    <v-sheet>
      <v-container fluid class="mt-0 mb-10">
        <v-row class="d-flex justify-center">
          <v-col class="home-page col-lg-10 col-md-12">
            <h2 class="home-page__title">
             Subscription plans for warehousing and fulfillment
            </h2>

            <h4 class="text-center grey--text text--darken-1" :class="$vuetify.breakpoint.mobile ? 'text-body-1' : ''">Warehouse space for rent & multi-channel fulfillment with same day delivery across UAE</h4>

            <section class="home-page__features py-0 my-0">
              <div class="home-page__feat">
                <img
                    class="home-page__feat-icon"
                    src="@/assets/imgs/hand-icon-1.png"
                    alt=""
                />
                <h5 class="home-page__feat-title">No Money Upfront</h5>
              </div>

              <div class="home-page__feat">
                <img
                    class="home-page__feat-icon"
                    src="@/assets/imgs/calender-icon-1.png"
                    alt=""
                />
                <h5 class="home-page__feat-title">7-Day Free Trial </h5>
              </div>

              <div class="home-page__feat">
                <img
                    class="home-page__feat-icon"
                    src="@/assets/imgs/month-icon-1.png"
                    alt=""
                />
                <h5 class="home-page__feat-title">Month-To-Month Renewal</h5>
              </div>
            </section>

            <AllPlans />
            <AllPlansSwiper />

          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- WHATSAPP -->
    <!-- <ws-whatsapp-popup category="plan" /> -->
    <WsFooter />
  </div>
</template>

<script>
import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import WsLogoHeader from "@/components/ws-logo-header";
import WsFooter from "@/components/ws-footer";
import AllPlans from '@/components/Home/AllPlans.vue'
import AllPlansSwiper from '@/components/Home/AllPlansSwiper.vue'
import WsWhatsappPopup from "@/components/ws-whatsapp-popup";
export default mixins(MyMixin).extend({
  name: "Home",
  components:{AllPlans, AllPlansSwiper, WsLogoHeader, WsWhatsappPopup, WsFooter},
  metaInfo: {
    title: metadata.Plans.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Plans.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  mounted() {
    this.trackMyPageView();
  }
});
</script>

<style>
@media screen and (max-width: 620px) {
  .home-page__features {
    gap: 0;
  }

  .home-page__feat-icon {
    width: 30px;
  }

  .home-page__feat-title {
    margin: 4px 0;
  }
}
</style>

<template>
  <div class="thank-you-page">
      <WsLogoHeader />
      <v-sheet
        class="d-flex justify-center flex-column mt-5"
        elevation="0"
      >
        <v-container class="mb-10">
          <section class="card">
            <h2 class="thank-you-page__title"> Thank you !  </h2>
            <div class="thank-you-page__img-container">
              <img src="@/assets/imgs/plan/thanks.png" alt="">
            </div>
            <p>
              You have successfully submitted your subscription request.<br/>
              WeStore team will reach out to you shortly.
              <br>
              <br>
              If you have any urgent requests feel free to contact us at
              <span class="number">
                    <a href="tel:+971585454161" class="text-decoration-none">+971 58 545 4161</a>
                </span>
            </p>
            <div class="d-flex flex-column align-center mt-5">
              <v-btn @click="toHome" role="link" color="primary" tile>Back to Home</v-btn>
            </div>
          </section>
        </v-container>
      </v-sheet>
    <!-- <ws-whatsapp-popup category="plan" :label="`Thank you page package ${this.packageId}`"/> -->
    <WsFooter />
  </div>
</template>

<script>
import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import WsLogoHeader from "@/components/ws-logo-header";
import WsWhatsappPopup from "@/components/ws-whatsapp-popup";
import WsFooter from "@/components/ws-footer";
export default mixins(MyMixin).extend({
  name: "ThankYou",
  components: {WsLogoHeader, WsFooter, WsWhatsappPopup},
  metaInfo: {
    title: metadata.ThankYou.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.ThankYou.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  methods: {
    toHome() {
      this.$router.push({name: 'Home'})
    }
  },
  computed: {
    packageId() {
      return this.$route.params.package;
    }
  },
  mounted() {
    this.trackMyPageView();
  }
});
</script>

<style lang="scss">

</style>